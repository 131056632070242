import {Component, OnInit, TemplateRef, Input} from '@angular/core';
import {NotificationService} from '../services/notification.service';
import {Router} from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Attachment} from '../models/attachment.model';
import {DomSanitizer} from '@angular/platform-browser';
import {HttpEventType, HttpResponse} from '@angular/common/http';
import Swal from 'sweetalert2';
import {FileUploadService} from '../services/upload/file-upload.service';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit {
  @Input() user: any;
  userType: string;
  modalRef: BsModalRef;
  attachmentModel: Attachment = new Attachment(null, null);
  showProofOfIncomeDropdown = false;
  documentTypes: any = [
    'My ID Document',
    'My Proof of Address',
    'My Matric Report',
    'My Academical Report',
    'Acceptance Letter (college, tertiary, etc)',
    'Qualification Certificate',
    'Guardian`s ID Document',
    'Mother`s ID Document',
    'Father`s ID Document',
    'Mother`s Proof of Income',
    'Father`s Proof of Income',
    'Guardian`s Proof of Income',
    'Disability Evidence',
    'Other',
  ];
  progressInfos: any[] = [];
  message: string[] = [];
  previews: string[] = [];
  selectedFiles?: any[];
  selectedPdf: any;
  captionPdf = 'Choose a PDF';
  viewAttachment = 'https://dummyimage.com/318x200/000/fff';
  constructor(
      public router: Router,
      public notify: NotificationService,
      public modalService: BsModalService,
      public sanitizer: DomSanitizer,
      public uploadService: FileUploadService,
  ) {
    this.userType = window.localStorage.getItem('user_type');
  }

  ngOnInit(): void {
  }

  openUploadFileModal(template6: TemplateRef<any>) {
    if (this.user?.profile === null) {
      this.notify.confirmDialog('Please complete 30% of your basic profile in order to upload documents', (event) => {
        this.router.navigate(['/student/profile']);
      }, 'Cannot Access Documents!', 'warning', false, 'go to profile');
      return;
    } else {
      this.modalRef = this.modalService.show(
          template6,
          Object.assign({}, {class: 'gray modal-md'})
      );
    }
  }

  onFileTypeChange() {
    // tslint:disable-next-line:max-line-length
    if (this.attachmentModel.name === 'Guardian`s Proof of Income' || this.attachmentModel.name === 'Father`s Proof of Income' || this.attachmentModel.name === 'Mother`s Proof of Income') {
      const confirmAlive = confirm('Would you like to confirm if the person associated with this payslip is still with us?');
      if (!confirmAlive) {
        // Handle the case where the person is not alive
        // For example, you can reset the selection or show an error message
        this.attachmentModel.name = 'Choose...'; // Reset the selection
        // You can also show an error message to the user
        alert('You cannot select this document type because the person is no longer with us.');
      }
    }
    // Displaying Proof Of Income Types
    this.showProofOfIncomeDropdown = this.attachmentModel.name === 'Mother\'s Proof of Income' ||
        this.attachmentModel.name === 'Father\'s Proof of Income' ||
        this.attachmentModel.name === 'Guardian\'s Proof of Income';
  }

  selectFiles(event: any): void {
    this.message = [];
    this.previews = [];
    this.progressInfos = [];
    this.selectedFiles = event.target.files;
    if (this.selectedFiles && this.selectedFiles[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.previews.push(e.target.result);
        this.selectedPdf = reader.result.toString();
      };
      const pdfToUpload = this.selectedFiles[0];
      reader.readAsDataURL(pdfToUpload);
      this.captionPdf = event.target.files[0].name;
    }
  }

  uploadFiles(): void {
    this.message = [];
    if (this.selectedFiles) {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        this.upload(i, this.selectedFiles[i]);
      }
    }
  }

  upload(idx: number, file: File): void {
    this.progressInfos[idx] = { value: 0, fileName: file.name };
    if (file) {

      const formData = new FormData();
      const notify = this.notify;
      formData.append('file', file);
      formData.append('name', this.attachmentModel.name);
      formData.append('description', this.attachmentModel.description);
      formData.append('profile_uuid', this.user.uuid);
      formData.append('file_type', 'pdf');
      //
      this.uploadService.uploadRelated(file, 'attachment/store', this.user.uuid, formData).subscribe(
          (event: any) => {
            if (event.type === HttpEventType.UploadProgress) {
              this.progressInfos[idx].value = Math.round((100 * event.loaded) / event.total);
            } else if (event instanceof HttpResponse) {
              Swal.fire('Upload Successful Upload!', 'File Upload', 'success');
              this.resetUploader();
            }
          }, (err: any) => {
            this.progressInfos[idx].value = 0;
            Swal.fire('Could not upload the file: ' + file.name, 'Upload Error', 'error');
          }
      );
    }
  }

  resetUploader() {
    this.message = [];
    this.previews = [];
    this.progressInfos = [];
    this.selectedFiles = [];
  }

  openViewFileModal(template6: TemplateRef<any>, url) {
    this.viewAttachment = url;
    this.modalRef = this.modalService.show(
        template6,
        Object.assign({}, { class: 'gray modal-xl' })
    );
  }
}
