import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-document501-table',
  templateUrl: './document501-table.component.html',
  styleUrls: ['./document501-table.component.scss']
})
export class Document501TableComponent implements OnInit {
  @Input() students: any;
  constructor() { }

  ngOnInit(): void {
  }

  extractDateOfBirth(idNumber: string): Date | null {
    if (!idNumber || idNumber.length !== 13) {
      return null; // ID number is invalid
    }

    // Extract year, month, and day from the ID number
    let year = parseInt(idNumber.substring(0, 2), 10);
    const month = parseInt(idNumber.substring(2, 4), 10);
    const day = parseInt(idNumber.substring(4, 6), 10);

    // Adjust the year if necessary (based on the first two digits)
    const currentYear = new Date().getFullYear();
    year += (year < currentYear % 100) ? 2000 : 1900;

    // Create a Date object
    const dateOfBirth = new Date(year, month - 1, day);

    return dateOfBirth;
  }
}
