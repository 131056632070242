import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SessionHandlerService} from '../../../services/session/session-handler.service';
import {NotificationService} from '../../../services/notification.service';
import {not} from 'rxjs/internal-compatibility';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginUserType: any;

  public user: any = {
    name: '',
    email: '',
    password: '',
    type: '',
    password_confirmation: ''
  };

  public admin: any;
  public forgotPassword: any;
  public resetPasswordModel: any;
  uuid: number;
  showDisclaimer = false;
  adminTab: any = true;
  clientTab: any = false;
  adminLoginHeader = 'Administration ';

  constructor(
    public apiService: ApiService,
    public router: Router,
    public sessionHandler: SessionHandlerService,
    private notify: NotificationService,
    private route: ActivatedRoute
  ) {
    window.localStorage.clear();
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params.uuid) {
        this.uuid = params.uuid;
        this.toggleUserType('RESETTING_PASSWORD');
      } else {
        this.toggleUserType('ADMIN');
      }
    });
  }

  toggleUserType(type) {
    if (type === 'ADMIN') {
      this.admin = {
        name: '',
        email: '',
        password: '',
        type: 'ADMIN',
      };
      this.loginUserType = type;
      // this.notify.confirmDialog('Do you want to login as System Administrator?', () => {
      //   this.admin = {
      //     name: '',
      //     email: '',
      //     password: '',
      //     type: 'ADMIN',
      //   };
      //   this.loginUserType = type;
      // });
    } else {
      this.loginUserType = type;
      if (type === 'FORGOT_PASSWORD') {
        this.forgotPassword = {
          email: '',
        };
      } else if (type === 'RESETTING_PASSWORD') {
        this.resetPasswordModel = {
          uuid: this.uuid,
          password: '',
          password_confirmation: '',
        };
      }
    }
    window.localStorage.setItem('user_type', type);
  }

  loginStudent(type = 'STUDENT', user = null) {
    this.user.type = type;
    const router = this.router;
    const sessionHandler = this.sessionHandler;
    const notify = this.notify;

    // tslint:disable-next-line:only-arrow-functions
    this.apiService.login('login', this.user ).then(function(res) {
      if (res) {
        if (res.user.type !== 'ADMIN') {
          sessionHandler.startSession(res, router, '/student/dashboard');
        }
      }
    });
  }
  forgotPasswordFunction() {
    const notify = this.notify;
    const router = this.router;
    this.apiService.forgotPassword('forgot-password', this.forgotPassword ).then(function(res) {
      if (res.status === 'success') {
        notify.successBasicNotification('Password Reset Successful', res.message);
        this.toggleUserType(false);
      } else {
        notify.errorBasicNotification('Password Reset Error', res.message);
      }
    });
  }

  resetPasswordFunction() {
    const notify = this.notify;
    const router = this.router;
    // tslint:disable-next-line:only-arrow-functions
    this.apiService.forgotPassword('reset-password', this.resetPasswordModel ).then(function(res) {
      if (res.status === 'success') {
        notify.successBasicNotification('Password Reset Successful', res.message);
        router.navigate(['/login']);
      } else {
        notify.errorBasicNotification('Password Reset Error', res.message);
      }
    });
  }

  registerStudent() {
    this.user.type = 'STUDENT';

    const router = this.router;
    const notify = this.notify;
    const sessionHandler = this.sessionHandler;
    const apiRegisterService = this.apiService;
    const apiHelpdeskService = this.apiService;
    const user = this.user;

    this.apiService.register('register', this.user).then((res) => {
      console.log('Register Response : ', res.toString());
      if (res) {
        console.log(res);
        this.apiService.helpdeskRegister('add_user', res);
        notify.confirmDialog(
          'Registration Successful! Do you want to Login?',
          () => {
            // tslint:disable-next-line:no-shadowed-variable
            apiRegisterService.login('login', user).then((res) => {
              if (res) {
                window.location.reload();
              }
            });
          },
          'Registration Successful!',
          'success'
        );
      }
    }).catch((error) => {
      if (error.error && error.error.errors && error.error.errors.email) {
        const emailErrorMessage = error.error.errors.email[0];
        notify.errorBasicNotification('Registration Failed', emailErrorMessage);
      } else if (error.error && error.error.errors && error.error.errors.password) {
        const passwordErrorMessage = error.error.errors.password[0];
        notify.errorBasicNotification('Registration Failed', passwordErrorMessage);
      } else {
        console.error('Error during registration:', error);
      }
    });
  }

  loginAdmin() {
    this.admin.type = 'ADMIN';
    const router = this.router;
    const sessionHandler = this.sessionHandler;
    const apiPartnerService = this.apiService;
    // tslint:disable-next-line:only-arrow-functions
    this.apiService.login('login', this.admin ).then(function(res) {
      if (res.user.type !== 'STUDENT') {
        window.localStorage.setItem('admin_type', res?.user?.admin_type);
        if (res?.user?.admin_type === 'ADMIN') {
          apiPartnerService.get_public('partner/default').then( data => {
            window.localStorage.setItem('company_logo', 'assets/images/fasset-logo.png');
            window.localStorage.setItem('related_uuid', data?.uuid);
            sessionHandler.startSession(res, router, '/dashboard');
          });
        } else {
          window.localStorage.setItem('related_uuid', res?.user?.related_uuid);
          window.localStorage.setItem('company_logo', res?.user?.partner?.logo);
          sessionHandler.startSession(res, router, `/dashboard/dashboard/${res?.user?.related_uuid}`);
        }
      }
    });
  }

  toggleDisclaimer(decision: string) {
    this.showDisclaimer = !this.showDisclaimer;
    if (decision === 'agreed') {
      this.toggleUserType('STUDENT');
    }
  }

  // tslint:disable-next-line:variable-name
  onTabChange(number: string) {
    this.adminTab = false;
    this.clientTab = false;

    if (number === '1') {
      this.adminTab = true;
      this.adminLoginHeader = 'Administration ';
    } else if (number === '2') {
      this.clientTab = true;
      this.adminLoginHeader = 'Client ';
    }
  }
}
