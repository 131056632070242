import {Component, OnInit, TemplateRef} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {Router} from '@angular/router';
import {SessionHandlerService} from '../../../services/session/session-handler.service';
import {NotificationService} from '../../../services/notification.service';
import {FileUploadService} from '../../../services/upload/file-upload.service';
import {Profile} from '../../../models/profile.model';
import {environment} from '../../../../environments/environment';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Attachment} from '../../../models/attachment.model';
import {HttpEventType, HttpResponse} from '@angular/common/http';
import Swal from 'sweetalert2';
import {DomSanitizer} from '@angular/platform-browser';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'app-filemanager',
  templateUrl: './filemanager.component.html',
  styleUrls: ['./filemanager.component.scss']
})
export class StudentFilemanagerComponent implements OnInit {

  user: any;
  modalRef: BsModalRef;
  profileModel = new Profile(null, null, null, null);
  attachmentModel: Attachment = new Attachment(null, null);
  profilePercentage = 0;
  showPercentage = true;
  showUploadProfile = false;
  apiBaseUrl = environment.apiPublic;
  attachmentFiles: any;
  showFolders = false;
  showList = true;
  viewAttachment = 'https://dummyimage.com/318x200/000/fff';

  // file upload members
  selectedFiles?: any[];
  progressInfos: any[] = [];
  message: string[] = [];
  previews: string[] = [];
  selectedPdf: any;
  captionPdf = 'Choose a PDF';
  showProofOfIncomeDropdown = false;
  //
  documentTypes: any = [
    'My ID Document',
    'My Proof of Address',
    'My Matric Report',
    'My Academical Report',
    'Acceptance Letter (college, tertiary, etc)',
    'Qualification Certificate',
    'Guardian`s ID Document',
    'Mother`s ID Document',
    'Father`s ID Document',
    'Mother`s Proof of Income',
    'Father`s Proof of Income',
    'Guardian`s Proof of Income',
    'Disability Evidence',
    'Other',
  ];

  constructor(
    public apiService: ApiService,
    public router: Router,
    public sanitizer: DomSanitizer,
    public notify: NotificationService,
    public uploadService: FileUploadService,
    public modalService: BsModalService,
    public authService: AuthService) {
    this.profileModel = new Profile(null, null, null, this.user);
    this.attachmentModel = new Attachment(null, null);
    this.profilePercentage = 0;
    this.showPercentage = true;
    this.showUploadProfile = false;
    this.apiBaseUrl = environment.apiPublic;
  }

  ngOnInit(): void {
    this.loadUser();
  }

  // auth check | fetch user
  loadUser(): void {
    this.authService.fetchUser().then((user) => {
      this.user = this.authService.getUser();
      this.profileModel = this.authService.getProfileModel();
      this.attachmentModel = new Attachment(null, this.profileModel.uuid);
      this.attachmentFiles = this.authService.getAttachments();
      this.profilePercentage = this.authService.getProfilePercentage();
    });
  }

  // modal view file
  openViewFileModal(template6: TemplateRef<any>, url) {
    this.viewAttachment = url;
    this.modalRef = this.modalService.show(
      template6,
      Object.assign({}, { class: 'gray modal-xl' })
    );
  }
  // modal upload file
  openUploadFileModal(template6: TemplateRef<any>) {
    if (this.user?.profile === null) {
      this.notify.confirmDialog('Please complete 30% of your basic profile in order to upload documents', (event) => {
        this.router.navigate(['/student/profile']);
      }, 'Cannot Access Documents!', 'warning', false, 'go to profile');
      return;
    } else {
      this.modalRef = this.modalService.show(
        template6,
        Object.assign({}, {class: 'gray modal-md'})
      );
    }
  }
  // file upload
  selectFiles(event: any): void {
    this.message = [];
    this.previews = [];
    this.progressInfos = [];
    this.selectedFiles = event.target.files;
    if (this.selectedFiles && this.selectedFiles[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.previews.push(e.target.result);
        this.selectedPdf = reader.result.toString();
      };
      const pdfToUpload = this.selectedFiles[0];
      reader.readAsDataURL(pdfToUpload);
      this.captionPdf = event.target.files[0].name;
    }
  }
  //
  upload(idx: number, file: File): void {
    this.progressInfos[idx] = { value: 0, fileName: file.name };
    if (file) {

      const formData = new FormData();
      const notify = this.notify;
      formData.append('file', file);
      formData.append('name', this.attachmentModel.name);
      formData.append('description', this.attachmentModel.description);
      formData.append('profile_uuid', this.profileModel.uuid);
      formData.append('file_type', 'pdf');
      //
      this.uploadService.uploadRelated(file, 'attachment/store', this.profileModel.uuid, formData).subscribe(
        (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.progressInfos[idx].value = Math.round((100 * event.loaded) / event.total);
          } else if (event instanceof HttpResponse) {
            Swal.fire('Upload Successful Upload!', 'File Upload', 'success');
            this.resetUploader();
          }
        }, (err: any) => {
          this.progressInfos[idx].value = 0;
          Swal.fire('Could not upload the file: ' + file.name, 'Upload Error', 'error');
        }
      );
    }
  }
  //
  uploadFiles(): void {
    this.message = [];
    if (this.selectedFiles) {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        this.upload(i, this.selectedFiles[i]);
      }
    }
  }
  //
  resetUploader() {
    this.message = [];
    this.previews = [];
    this.progressInfos = [];
    this.selectedFiles = [];
    this.loadUser();
  }
  // delete attachment
  deleteAttachment(uuid) {
    this.notify.confirmDialog('Do you want to delete this file?', this.destroyFile(uuid));
  }
  //
  destroyFile(uuid) {
    this.apiService.get_private('attachment/delete/' + uuid).then( data => {
      this.loadUser();
    });
  }
  // toggle file views
  toggleFoldersList(folders: boolean, list: boolean) {
    this.showFolders = folders;
    this.showList = list;
  }
  onFileTypeChange() {
    // tslint:disable-next-line:max-line-length
    if (this.attachmentModel.name === 'Guardian`s Proof of Income' || this.attachmentModel.name === 'Father`s Proof of Income' || this.attachmentModel.name === 'Mother`s Proof of Income') {
      const confirmAlive = confirm('Would you like to confirm if the person associated with this payslip is still with us?');
      if (!confirmAlive) {
        // Handle the case where the person is not alive
        // For example, you can reset the selection or show an error message
        this.attachmentModel.name = 'Choose...'; // Reset the selection
        // You can also show an error message to the user
        alert('You cannot select this document type because the person is no longer with us.');
      }
    }
    // Displaying Proof Of Income Types
    this.showProofOfIncomeDropdown = this.attachmentModel.name === 'Mother\'s Proof of Income' ||
        this.attachmentModel.name === 'Father\'s Proof of Income' ||
        this.attachmentModel.name === 'Guardian\'s Proof of Income';
  }
}
